import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <footer>
          <div className="footer-block">
            <div className="brand">
              <Link to="/" className="logo" aria-label="homepage">
                <img src="../img/SwiftFoxLogo.svg" alt="homepage" />
              </Link>
            </div>
            <div className="social">
              <a href="/" className="item">
                <img src="../img/Facebook.svg" alt="facebook" />
              </a>
              <a href="/" className="item">
                <img src="../img/Twitter.svg" alt="twitter" />
              </a>
              <a href="/" className="item">
                <img src="../img/LinkedIn.svg" alt="linkedin" />
              </a>
            </div>
          </div>
          <div className="footer-block">
            <div className="item">
              <p>
                9 South Molton Street
                <br />
                Mayfair, London
                <br />
                W1K 5QH, United Kingdom
              </p>
            </div>
            <div className="item special-gap">
              <div className="cta">
                <a href="tel:+44 203 751 7522">
                  <div className="alt-button">
                    <img alt="Phone" src="../img/Phone.svg" />
                  </div>
                </a>
                <a href="tel:+44 203 751 7522">+44 (0)203 751 7522</a>
              </div>
              <div className="cta">
                <a href="mailto:support@swiftfox.co.uk#">
                  <div className="alt-button">
                    <img alt="Support" src="../img/Support.svg" />
                  </div>
                </a>
                <a href="mailto:support@swiftfox.co.uk">
                  support@swiftfox.co.uk
                </a>
              </div>
            </div>
            <div className="item">
              <a href="/legal-policies">Legal Policies</a>
              <a href="/complaints-and-safeguarding">Complaints and Safeguarding</a>
              <a href="/privacy-policy">Privacy Policy </a>
              <a href="/terms-conditions">Terms and Conditions</a>
              <a href="/cookie-policy">Cookie Policy</a>
            </div>
            <div className="item">
              <p>©2024 Swiftfox</p>
              <p>All rights reserved</p>
              <p>
                Website by{" "}
                <a
                  href="https://futureshore.co.uk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Future Shore™
                </a>
              </p>
            </div>
          </div>
          <div className="footer-block">
            <p className="private">
              Payment services for Swiftfox Limited are provided by The Currency
              Cloud Limited. Registered in England No. 06323311. Registered
              Office: Stewardship Building 1st Floor, 12 Steward Street London
              E1 6FQ. The Currency Cloud Limited is authorised by the Financial
              Conduct Authority under the Electronic Money Regulations 2011 for
              the issuing of electronic money (FRN: 900199){" "} <br/>
              For more information, please refer to The Currency {"Cloud's"} {" "} 
              <a
                  href="https://www.currencycloud.com/legal/terms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms Of Use
                </a>
            </p>
          </div>
          <div className="footer-block">
            <div>
              <p className="private">
                For clients based in the European Economic Area, payment
                services for Swiftfox Limited are provided by CurrencyCloud
                B.V.. Registered in the Netherlands No. 72186178. Registered
                Office: Nieuwezijds Voorburgwal 296 - 298, Mindspace Nieuwezijds
                Office 001 Amsterdam. CurrencyCloud B.V. is authorised by the
                DNB under the Wet op het financieel toezicht to carry out the
                business of an electronic-money institution (Relation Number:
                R142701).{" "}
              </p>
            </div>
            <div>
              <p className="private">
                For clients based in the United States, payment services for
                Swiftfox Limited are provided by The Currency Cloud Inc. which
                operates in partnership with Community Federal Savings Bank
                (CFSB) to facilitate payments in all 50 states in the US. CFSB
                is registered with the Federal Deposit Insurance Corporation
                (FDIC Certificate# 57129). The Currency Cloud Inc is registered
                with FinCEN and authorised in 39 states to transmit money (MSB
                Registration Number: 31000206794359). Registered Office: 104 5th
                Avenue, 20th Floor, New York , NY 10011.
              </p>
            </div>
            <div>
              <p className="private">
                For clients based in the United Kingdom and rest of the world,
                payment services for Swiftfox Limited are provided by The
                Currency Cloud Limited. Registered in England and Wales No.
                06323311. Registered Office: Stewardship Building 1st Floor, 12
                Steward Street London E1 6FQ. The Currency Cloud Limited is
                authorised by the Financial Conduct Authority under the
                Electronic Money Regulations 2011 for the issuing of electronic
                money (FRN: 900199).
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
};

export default Footer;
